import { request as axios } from '@/util/request';
import { listToMap } from '@/util/global';
import { message } from 'ant-design-vue';
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');

interface QuerySendData {
  product_id?: string;
  product_name?: string;
  category_id?: number;
  is_show?: number;
  is_recommend?: number;
  start_time?: string;
  end_time?: string;
  price_min?: number | string;
  price_max?: number | string;
  stock_min?: number;
  stock_max?: number;
  sales_min?: number;
  sales_max?: number;
  page?: number;
  config_limit_time?: number;
  zone_box_id?: number;
  filter_promotion_product?: number;
  is_vip?: number;
}

interface AddSendData {
  title: string;
  subtitle: string;
  price: number;
  warehouse: string;
  is_recommend: boolean;
  video_url: string;
  viewpager_url: string;
  detail_image_url: string;
  goods_services: string;
  goods_parameter: string;
  putaway_type: number;
  putaway_time: string;
  sort: number;
}

const API = {
  Productsort: '/boss/product/spu/sort', //'/boss/operation/activity/product/sort'
  WareSort: '/boss/operation/activity/sort',
  spu: '/boss/product/spu',
  sort: '/boss/product/spu/sort',
  status: '/boss/product/spu/putaway/9999',
  relevance: '/boss/product/spu/relevance',
  stock: '/boss/product/spu/stock/',
};

export const WARE_STATUS_LIST = [
  {
    value: '0',
    label: '全部',
  },
  {
    value: '1',
    label: '上架中',
  },
  {
    value: '2',
    label: '已下架',
  },
];

export const WARE_STATUS_MAP = listToMap(WARE_STATUS_LIST);

function formatWare(remote: any = {}): RemotePagination<WareData> {
  return {
    current: remote.current || 1,
    limit: remote.limit || 10,
    detail: remote.detail || [],
    total: remote.total || 0,
    last: remote.last || 1,
  };
}

export function formatWareData(remote: any = {}): WareData {
  return {
    ...remote,
    banner_url_list: remote.viewpager_url.split(','),
    detail_url_list: remote.detail_image_url.split(','),
    is_relevance: remote.is_relevance === 1,
    origin_price: remote.price, // 上面的price是经过处理后的price，originprice是未经处理的price
    sale_count: remote.sales,
    service_list: remote.goods_services,
    param_list: remote.goods_parameter,
    online_time_type: remote.putaway_type,
    online_time: remote.putaway_time,
    status: remote.is_show,
    status_text: WARE_STATUS_MAP[remote.is_show],
    category_id:
      remote.category.parent_id === 0
        ? [remote.category.id]
        : [remote.category.parent_id, remote.category.id],
    category: remote.category ? remote.category : {},
    vip_product: remote.vip_product,
  };
}

/**
 * 查询商品数据
 * @param current 当前分页
 */
async function queryWare(current: number = 1, send_data?: QuerySendData) {
  clean(send_data);
  const _send_data = {
    ...send_data,
    // limit:2,
    current,
  };
  const url = getUrl(API.spu, _send_data);
  const res = await axios.get(url);
  if (res.status !== 200) {
    //message.error((res as any).message);
  }
  const ware = formatWare(res.data);
  ware.detail = ware.detail.map((item: any) => formatWareData(item));
  console.log(ware);

  return ware;
}
//获取已经过虑的商品
async function getHomeActivityWare(
  current: number,
  home_product_activity_id: number,
  limit: number
) {
  const send_data = {
    current,
    home_product_activity_id,
    limit,
  };
  const url = getUrl(API.spu, send_data);
  const res = await axios.get(url);
  const ware = formatWare(res.data);
  ware.detail = ware.detail.map((item: any) => formatWareData(item));
  return ware;
}
/**
 * 获取单个商品的数据
 * @param id 商品 id
 */
async function queryWareById(id: number) {
  const res = await axios.get(`${API.spu}/${id}`);
  return formatWareData(res.data);
}

/**
 * 新增商品
 * @param send_data
 */
async function addWare(send_data: AddSendData) {
  return await axios.post(API.spu, send_data);
}

/**
 * 编辑商品
 * @param id 商品 id
 * @param send_data
 */
async function updateWare(id: number, send_data: AddSendData) {
  return await axios.put(`${API.spu}/${id}`, send_data);
}

/**
 * 批量上下架
 * @param is_show 上/下架
 * @param id_array 商品 id 数组
 */
async function updateWareStatus(is_show: number, id_array: any) {
  return await axios.put(API.status, {
    is_show,
    id_array,
  });
}

/**
 * 查看关联
 * @param id_array 商品 id 数组
 */
async function queryWareConnect(id_array: number[]) {
  const res = await axios.get(`${API.relevance}?id_array=${id_array.join()}`);
  return res;
}

/**
 * 查看关联
 * @param id_array 商品 id 数组
 */
async function wareConnect(id_array: number[]) {
  const res = await axios.post(API.relevance, { id_array });
  return res;
}

/**
 * 批量关联
 */
async function updateWareConnect(id_array: number[]) {
  return await axios.post(API.relevance, { id_array });
}

/**
 * 解除关联
 * @param id 商品 id
 */
async function disconnect(id: number) {
  return await axios.put(`${API.relevance}/${id}`);
}

/**
 *  修改商品排序值
 * @param id 商品 id
 * @param sort 排序值
 */
async function updateWareSort(id: number, sort: number) {
  return await axios.put(`${API.Productsort}/${id}`, { sort });
}
async function updateSort(id: number, sort: number) {
  return await axios.put(`${API.WareSort}/${id}`, { data: sort });
}
// 修改单个商品库存
async function updateStock(id: number, stock: number) {
  return await axios.put(`${API.stock}${id}`, {
    stock,
  });
}

export default queryWare;
export {
  queryWare,
  queryWareById,
  queryWareConnect,
  wareConnect,
  addWare,
  disconnect,
  updateWare,
  updateWareConnect,
  updateWareStatus,
  updateWareSort,
  updateStock,
  getHomeActivityWare,
};
