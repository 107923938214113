import { request as axios } from '@/util/request';
const clean = require('bmh/clean-empty-obj');

interface WareCategory {
  id: number;
  sort: number;
  name: string;
  level: number;
  level_text: string;
  parent_id: number;
  children: WareCategory[];
}

const API = {
  ware_category: '/boss/product/category',
  openscreen: '/boss/operation/openscreen'
};

// 转换数据格式
function formatCategoryData(data: any): WareCategory[] {
  return data.map((item: any) => {
    return {
      id: item.id,
      sort: item.sort,
      name: item.name,
      parent_id: item.parent_id,
      level: item.level,
      level_text: item.level === 1 ? '一级分类' : '二级分类',
      children: item.parent ? formatCategoryData(item.parent) : null,
    };
  });
}

function formatSimpleCategory(data: any) {
  return data.map((item: any) => {
    let _item: any = {
      id: item.id,
      name: item.name,
    };
    if (item.parent && item.parent.length > 0) {
      const children = formatSimpleCategory(item.parent);
      _item = {
        ..._item,
        children,
      };
    }
    return _item;
  }) as any[];
}

// 获取商品分类数据
async function queryWareCategory() {
  const res = await axios.get(API.ware_category);
  return formatCategoryData(res.data);
}

// 简单的分类列表，只包含 id 和 name
async function getSimpleCategory() {
  const res = await axios.get(API.ware_category);
  return formatSimpleCategory(res.data);
}

/**
 *
 * @param name 商品分类名
 * @param sort 排序值
 * @param level 级别
 * @param parent_id 商品分类名 id
 */
async function addNewCategory(name: string, sort: number, level: number, parent_id: number) {
  return await axios.post(API.ware_category, {
    name,
    sort,
    level,
    parent_id,
  });
}

/**
 * 删除分类
 * @param id 当前分类的 id
 */
async function deleteCategory(id: number) {
  return await axios.delete(`${API.ware_category}/${id}`);
}

/**
 * 编辑商品分类
 * @param id 分类 id
 * @param sort 当前分类排序值
 * @param name 当前分类名
 */
async function updateCategory(id: number, sort: number | undefined, name: string) {
  const send_data = {
    sort,
    name,
  };
  clean(send_data);
  return await axios.put(`${API.ware_category}/${id}`, send_data);
}

async function getOpenscreen() {
  return await axios.get(`${API.openscreen}`);
}
async function postOpenscreen(data: any) {
  return await axios.post(`${API.openscreen}`, data);
}


export default queryWareCategory;
export { addNewCategory, getSimpleCategory, deleteCategory, updateCategory, queryWareCategory, getOpenscreen, postOpenscreen };
