
import { Component, Vue, Prop, Model } from 'vue-property-decorator';

@Component
export default class EditableCell extends Vue {
  @Prop() text!: string | number;
  @Prop({ type: Number }) maxLength!: number; // 普通输入框的最大长度
  @Prop({ type: Number }) max!: number; // 数字输入框的最大值
  @Prop({ type: Number }) min!: number; // 数字输入框的最小值
  @Prop({ type: Boolean, default: false }) isNumber!: boolean; // 是否需要显示成数字输入框
  //@Model('change') value!: string | number;
  @Prop({ type: Boolean, default: true }) isShowIcon!: boolean;
  @Prop({ type: String, default: '' })
  dis_type!: string;
  editable: boolean = false;
  value: string | number = this.text;
  disable_zs: boolean = false; //整数提示框是否显示
  disable_xs: boolean = false; //小数提示框是否显示
  handleInputChange(e: any) {
    this.value = e.target.value;
  }

  //数字输入框change事件
  handleNumberChange(value: number) {
    const res = /(^[0-9]\d*$)/; //正整数正则
    const ret = /^(-?\d+)(\.\d{1,2})?$/; //正则定义两位小数
    if (this.dis_type == 'xs') {
      if (value != null && String(value) != '' && (value < 0 || !ret.test(String(value)))) {
        this.disable_xs = true;
      } else {
        this.disable_xs = false;
      }
    } else {
      if (value != null && String(value) != '' && (value < 0 || !res.test(String(value)))) {
        this.disable_zs = true;
      } else {
        this.disable_zs = false;
      }
    }

    this.value = value;
  }
  // 完成
  confirm() {
    this.editable = false;
    if (this.value === null) {
      //||this.value<this.min
      this.$message.error('修改失败');
      return;
    }
    this.$emit('confirm', this.value);
    console.log(this.value);
  }

  edit() {
    this.value = this.text;
    this.editable = true;
  }
}
