export const STATUS_LIST: any = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '已上架',
  },
  {
    value: 2,
    label: '已下架',
  },
];

export const STATUS_LIST_MAP = STATUS_LIST.reduce((map: any, ele: any) => {
  map[ele.value] = ele.label;
  return map;
}, {});

export const RECOMMEND_LIST: any = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '推荐',
  },
  {
    value: 2,
    label: '不推荐',
  },
];

export const RECOMMEND_LIST_MAP = RECOMMEND_LIST.reduce((map: any, ele: any) => {
  map[ele.value] = ele.label;
  return map;
}, {});

export const RELATE_LIST: any = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '关联',
  },
  {
    value: 2,
    label: '不关联',
  },
];

export const RELATE_LIST_MAP = RELATE_LIST.reduce((map: any, ele: any) => {
  map[ele.value] = ele.label;
  return map;
}, {});
